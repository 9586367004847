import moment from "moment";

export default {
  formattedDatePolish: function(date) {
    moment.locale("pl");
    return moment(date).format("DD.MM.YYYY");
  },
  formattedDate: function(date) {
    moment.locale("pl");
    return moment(date).format("DD.MM.YYYY");
  },
  formattedDateHour: function(date) {
    moment.locale("pl");
    return moment(date).format("MMMM Do YYYY, HH:mm:ss");
  },
  formattedDateTime: function(date) {
    moment.locale("pl");
    return moment(date).format("DD.MM.YYYY HH:mm");
  },
  formattedTime: function(date) {
    moment.locale("pl");
    return moment(date).format("HH:mm:ss");
  },
  formattedDays: function(date, hours) {
    moment.locale("pl");
    return moment(date)
      .add(hours, "hours")
      .format("MMMM Do YYYY, HH:mm:ss");
  },
  formatRole: function(role) {
    var text = role.replace("_", " ");
    var text1 = text.charAt(0) + text.slice(1).toLowerCase();
    if (text1 === "Realized paid") {
      return "Zrealizowano";
    }
    if (text1 === "Sent") {
      return "Wysłano ofertę";
    }
    if (text1 === "Partially realized") {
      return "Częściowo zrealizowane";
    }
    if (text1 === "Realized unpaid") {
      return "Zrealizowano (nieopłacone)";
    }
    if (text1 === "Ordered") {
      return "Zamówiono";
    }
    if (text1 === "Not realized") {
      return "Nie zrealizowano";
    }
    return text1;
  },
  roundNumber(number, precision) {
    var num = parseFloat(number);
    return num.toFixed(precision);
  },

  formatQuillText(text) {
    if (!text) {
      return "";
    }
    return text
      .replaceAll(`class="ql-align-center"`, `style="text-align: center"`)
      .replaceAll(`class="ql-align-justify"`, `style="text-align: justify"`)
      .replaceAll(`class="ql-align-right"`, `style="text-align: right"`)

      .replaceAll(`class="ql-size-small"`, `style="font-size: 9.75px"`)
      .replaceAll(`class="ql-size-normal"`, `style="font-size: 13px"`)
      .replaceAll(`class="ql-size-large"`, `style="font-size: 19.5px"`)
      .replaceAll(`class="ql-size-huge"`, `style="font-size: 32.5px"`)
      .replaceAll("<p ", `<p class="no-margin-paragraph"`)
      .replaceAll("<p>", `<p class="no-margin-paragraph">`);
  },

  weekdays() {
    return [
      "Niedziela",
      "Poniedziałek",
      "Wtorek",
      "Środa",
      "Czwartek",
      "Piątek",
      "Sobota",
    ];
  },

  formattedTimeWithoutSeconds: function(date) {
    moment.locale("pl");
    return moment(date).format("HH:mm");
  },
};
