<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="400px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Filtruj kontakty</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <v-card flat tile elevation="0">
          <v-card-title>Sortowanie</v-card-title>
          <v-card-actions class="flex-column">
            <v-select
              style="width: 90%"
              dense
              outlined
              clearable
              label="Sposób sortowania"
              item-value="value"
              item-text="name"
              v-model="selectedSortingType"
              :items="sortingTypes"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
            <v-select
              style="width: 90%"
              dense
              outlined
              clearable
              label="Sortuj po"
              item-value="value"
              item-text="name"
              v-model="selectedSortingCategory"
              :items="sortingCategories"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
          </v-card-actions>
          <v-card-title>Filtrowanie</v-card-title>
          <v-card-actions class="flex-column">
            <v-select
              style="width: 90%"
              dense
              outlined
              clearable
              label="Wybierz kategorię"
              item-value="id"
              item-text="name"
              v-model="selectedCategory"
              :items="categoriesLevel1"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
          </v-card-actions>

          <v-card-actions class="flex-column">
            <v-select
              style="width: 90%"
              dense
              outlined
              clearable
              label="Wybierz podkategorię"
              item-value="id"
              item-text="name"
              v-model="selectedCategoryLevel2"
              :items="categoriesLevel2"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
          </v-card-actions>

          <v-card-actions class="flex-column py-10 px-10"
            ><WhiteButton
              width="100%"
              text="Filtruj"
              border
              class="mt-3 ml-0"
              @click.native="filterCustomers()"
            />
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import WhiteButton from "../buttons/WhiteButton";
import BlackButton from "../buttons/BlackButton";
export default {
  name: "FilterCustomersModal",
  props: ["isVisible", "startSorting"],

  components: {
    WhiteButton,
    BlackButton,
  },
  mounted() {
    if (this.startSorting) {
      this.selectedSortingCategory = this.startSorting.sortBy0;
      this.selectedSortingType = this.startSorting.sortBy1;
      this.selectedCategory = this.startSorting.category;
      this.selectedCategoryLevel2 = this.startSorting.categoryLevel2;
    }
  },
  data: () => ({
    selectedCustomers: [],
    filter: "",
    sortingTypes: [
      { name: "malejąco", value: "DESC" },
      { name: "rosnąco", value: "ASC" },
    ],
    sortingCategories: [
      { name: "Imię", value: "fullname" },
      { name: "Data utworzenia", value: "createdAt" },
      { name: "Adres", value: "address" },
      { name: "Notatka", value: "note" },
      { name: "Data kontaktu", value: "contactDate" },
    ],
    selectedSortingType: "DESC",
    selectedSortingCategory: "createdAt",
    selectedCategory: "",
    selectedCategoryLevel2: "",
  }),
  computed: {
    categories() {
      return this.$store.getters["customers/getCustomerCategories"];
    },
    categoriesLevel1() {
      if (this.categories && this.categories.length > 0) {
        return [
          ...this.categories.filter((c) => !c.level || c.level === 1),
          { id: "", name: "Brak kategorii" },
        ];
      } else {
        return [];
      }
    },
    categoriesLevel2() {
      if (this.categories && this.categories.length > 0) {
        return [
          ...this.categories
            .filter((c) => c.level === 2)
            .sort((a, b) => a.id - b.id),
          { id: "", name: "Brak podkategorii" },
        ];
      } else {
        return [];
      }
    },
  },
  watch: {},
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    filterCustomers() {
      const sorting = {
        sortBy0: this.selectedSortingCategory,
        sortBy1: this.selectedSortingType,
        category: this.selectedCategory,
        categoryLevel2: this.selectedCategoryLevel2,
      };
      this.$emit("fetchCustomers", sorting);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10000000;
}
</style>
