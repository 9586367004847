<template>
  <v-row justify="center">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="500px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
      @keydown.enter="deleteAction()"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title v-if="promotionalCode"
            >Usunięcie kodu rabatowego</v-toolbar-title
          >
          <v-toolbar-title v-if="product">Usunięcie produktu</v-toolbar-title>
          <v-toolbar-title v-if="gadget">Usunięcie gadżetu</v-toolbar-title>
          <v-toolbar-title v-if="shopping"
            >Usunięcie produktu z koszyka</v-toolbar-title
          >
          <v-toolbar-title v-if="calculation"
            >Usunięcie kalkulacji</v-toolbar-title
          >
          <v-toolbar-title v-if="contact">Usunięcie kontaktu</v-toolbar-title>
          <v-toolbar-title v-if="customer">Usunięcie kontaktu</v-toolbar-title>
          <v-toolbar-title v-if="tab">Usunięcie zakładki</v-toolbar-title>
          <v-toolbar-title v-if="user">Usunięcie użytkownika</v-toolbar-title>
        </v-toolbar>
        <v-card-actions class="bold pt-5 d-flex justify-center">
          <div
            v-if="promotionalCode"
            class="text-center"
            style="font-size: 1.2rem"
          >
            Na pewno chcesz usunąć kod: "{{ promotionalCode.code }}"?
          </div>
          <div v-if="product" class="text-center" style="font-size: 1.2rem">
            Na pewno chcesz usunąć produkt: "{{ product.name }}"?
          </div>
          <div v-if="gadget" class="text-center" style="font-size: 1.2rem">
            Na pewno chcesz usunąć gadżet: "{{ gadget.name }}"?
          </div>
          <div v-if="shopping" class="text-center" style="font-size: 1.2rem">
            Na pewno chcesz usunąć produkt: "{{ shopping.name }}"?
          </div>
          <div v-if="calculation" class="text-center" style="font-size: 1.2rem">
            Na pewno chcesz usunąć kalkulację: "{{ calculation.id }}"?
          </div>
          <div v-if="contact" class="text-center" style="font-size: 1.2rem">
            Napewno chcesz usunąć kontakt
            {{ contact.topic ? `"${contact.topic}"` : "" }}?
          </div>
          <div v-if="customer" class="text-center" style="font-size: 1.2rem">
            Na pewno chcesz usunąć kontakt: "{{ customer.fullname }}"?
          </div>
          <div
            v-if="calculationProduct"
            class="text-center"
            style="font-size: 1.2rem"
          >
            Na pewno chcesz usunąć produkt: "{{
              calculationProduct.calculationProductName
            }}"?
          </div>
          <div
            v-if="shoppingCartProduct"
            class="text-center"
            style="font-size: 1.2rem"
          >
            Na pewno chcesz usunąć produkt: "{{
              shoppingCartProduct.productName
            }}"?
          </div>
          <div v-if="tab" class="text-center" style="font-size: 1.2rem">
            Na pewno chcesz usunąć zakładkę: "{{ tab.name }}"?
          </div>
          <div v-if="user" class="text-center" style="font-size: 1.2rem">
            Na pewno chcesz usunąć użytkownika: "{{ user.fullname }}"?
          </div>
          <div v-if="faq" class="text-center" style="font-size: 1.2rem">
            Na pewno chcesz usunąć pytanie: "{{ faq.question }}"?
          </div>
          <div
            v-if="instructionalVideo"
            class="text-center"
            style="font-size: 1.2rem"
          >
            Na pewno chcesz usunąć film instruktażowy: "{{
              instructionalVideo.title
            }}"?
          </div>
          <div v-if="userTask" class="text-center" style="font-size: 1.2rem">
            Na pewno chcesz usunąć zadanie: "{{ userTask.title }}"?
          </div>
        </v-card-actions>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />

        <v-card-actions class="d-flex justify-center pt-5 pb-5">
          <YellowButton @click.native="deleteAction()" width="30%" text="Tak" />
          <YellowButton @click.native="closeModal()" width="30%" text="Nie" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BlackButton from "../buttons/BlackButton";
import YellowButton from "../buttons/YellowButton";
export default {
  name: "ConfirmDeleteModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    product: {
      type: [Object, String],
      default: null,
    },
    gadget: {
      type: [Object, String],
      default: null,
    },
    shopping: {
      type: [Object, String],
      default: null,
    },
    calculation: {
      type: [Object, String],
      default: null,
    },
    contact: {
      type: [Object, String],
      default: null,
    },
    customer: {
      type: [Object, String],
      default: null,
    },
    calculationProduct: {
      type: [Object, String],
      default: null,
    },
    shoppingCartProduct: {
      type: [Object, String],
      default: null,
    },
    promotionalCode: {
      type: [Object, String],
      default: null,
    },
    tab: {
      type: [Object, String],
      default: null,
    },
    user: {
      type: [Object, String],
      default: null,
    },
    faq: {
      type: [Object, String],
      default: null,
    },
    instructionalVideo: {
      type: [Object, String],
      default: null,
    },
    userTask: {
      type: [Object, String],
      default: null,
    },
  },
  components: {
    BlackButton,
    YellowButton,
  },
  data: () => ({}),
  computed: {},
  methods: {
    async deleteAction() {
      if (this.promotionalCode) {
        await this.$store.dispatch("promotionalCodes/deletePromotionalCode", {
          id: this.promotionalCode.id,
        });
        this.closeModal();
      }
      if (this.product) {
        await this.$store.dispatch("catalogue/deleteProduct", this.product.id);
        this.closeModal();
      }
      if (this.gadget) {
        await this.$store.dispatch("gadgets/deleteGadget", this.gadget.id);
        this.closeModal();
      }
      if (this.shopping) {
        await this.$store.dispatch("catalogue/deleteProduct", this.product.id);
        this.closeModal();
      }
      if (this.contact) {
        let object = {
          id: this.contact.id,
          customerId: this.contact.customerId,
        };
        await this.$store.dispatch("customers/deleteContact", object);
        this.closeModal(true);
      }
      if (this.calculation && !this.calculationProduct) {
        if (!this.calculation.isDeleted) {
          await this.$store.dispatch(
            "calculation/softDeleteCalculation",
            this.calculation.id
          );
          this.closeModal(true);
        } else {
          await this.$store.dispatch(
            "calculation/deleteCalculation",
            this.calculation.id
          );
          this.closeModal(true);
        }
      }
      if (this.customer) {
        if (!this.customer.isDeleted) {
          await this.$store.dispatch(
            "customers/softDeleteCustomer",
            this.customer.id
          );
          this.closeModal(true);
        } else {
          await this.$store.dispatch(
            "customers/deleteCustomer",
            this.customer.id
          );
          this.closeModal(true);
        }

        this.closeModal();
      }
      if (this.calculationProduct) {
        await this.$store.dispatch("calculation/deleteCalculationProduct", {
          calculationId: this.calculationProduct.calculationId,
          calculationProductId: this.calculationProduct.calculationProductId,
        });
        this.$store.dispatch(
          "calculation/fetchCalculationById",
          this.calculationProduct.calculationId
        );
        this.closeModal(true);
      }
      if (this.shoppingCartProduct) {
        await this.$store.dispatch(
          "shoppingCart/deleteProductFromShoppingCart",
          {
            shoppingCartId: this.shoppingCartProduct.shoppingCartId,
            productId: this.shoppingCartProduct.productId,
          }
        );
        this.$store.dispatch("shoppingCart/fetchShoppingCart", {
          limit: this.shoppingCartProduct.limit,
          sorting: this.shoppingCartProduct.sorting,
        });
        this.closeModal(true);
      }
      if (this.tab) {
        await this.$store.dispatch("catalogue/deleteTab", this.tab.id);
        this.closeModal();
      }
      if (this.user) {
        await this.$store.dispatch("user/adminDeleteAccount", this.user.id);
        this.closeModal();
        this.$store.dispatch("user/fetchUsers");
      }
      if (this.faq) {
        await this.$store.dispatch("faq/deleteFaq", this.faq.id);
        this.closeModal();
        this.$store.dispatch("faq/fetchFaqs");
      }
      if (this.instructionalVideo) {
        await this.$store.dispatch(
          "instructionalVideo/deleteInstructionalVideo",
          this.instructionalVideo.id
        );
        this.closeModal();
        this.$store.dispatch("instructionalVideo/fetchInstructionalVideos");
      }
      if (this.userTask) {
        await this.$store.dispatch("userTask/deleteUserTask", this.userTask.id);
        this.closeModal();
        await this.$store.dispatch("userTask/fetchUserTasks", this.userTask.id);
      }
    },

    closeModal(deleted) {
      if (deleted) {
        this.$emit("closeModal", true);
        if (this.shoppingCartProduct || this.calculationProduct) {
          this.$emit("clearUpdatedProductsList");
        }
      } else {
        this.$emit("closeModal", false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 1%;
  z-index: 10000000;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
</style>
