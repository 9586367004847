const productionServer = true;
const isProduction = productionServer;

export const URL = isProduction
  ? "https://formyeveroffice.com/api"
  : "http://localhost:3005";
export const API_URL = isProduction ? `${URL}/api/` : `${URL}/api/`;
export const IMAGE_URL = isProduction ? `${URL}/assets/` : `${URL}/assets/`;
export const PAYMENT_URL = isProduction
  ? "https://secure.przelewy24.pl/trnDirect"
  : "https://sandbox.przelewy24.pl/trnDirect";

export const SENTRY_DSN = "https://f2509b73813d4445a57e2257dd775bf7@o1097517.ingest.sentry.io/6119112"

export const GOOGLE_CALENDAR_SCOPES =
  "https://www.googleapis.com/auth/calendar";
export const GOOGLE_CALENDAR_DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
export const GOOGLE_CALENDAR_CLIENT_ID =
  "725932002379-b6vdellrnncrt6ncp1lv2clq3b5m2ean.apps.googleusercontent.com";
export const GOOGLE_CALENDAR_API_KEY =
  "AIzaSyC82HxvGF9QaxMMlqW8oY5eMXTARCO6aKQ";























































export const VERSION=4.15
