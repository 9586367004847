import { render, staticRenderFns } from "./ConfirmDeleteModal.vue?vue&type=template&id=ded6b402&scoped=true&"
import script from "./ConfirmDeleteModal.vue?vue&type=script&lang=js&"
export * from "./ConfirmDeleteModal.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmDeleteModal.vue?vue&type=style&index=0&id=ded6b402&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ded6b402",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardActions,VDialog,VRow,VToolbar,VToolbarTitle})
