var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center","dense":""}},[_c('v-col',[_c('v-card',{staticClass:"d-flex align-center justify-center pb-5",attrs:{"tile":"","elevation":"0"}},[_c('v-card-title',{staticClass:"black--text justify-center display-1"},[_c('span',{staticClass:"font"},[_vm._v("Panel Administratora")])])],1),_c('v-card',{staticClass:"d-flex align-center justify-space-between",attrs:{"tile":"","elevation":"0"}}),_c('v-card',{staticClass:"d-flex justify-space-around flex-wrap",attrs:{"tile":"","elevation":"0"}},_vm._l((_vm.cards),function(item){return _c('v-card-text',{key:item.value,staticClass:"text-center fontSize px-1 my-1",class:{
          selectedTab: _vm.selectedTab === item.value,
          background: _vm.selectedTab === item.value,
          unselectedTab: _vm.selectedTab != item.value,
        },staticStyle:{"cursor":"pointer","width":"100px"},on:{"click":function($event){_vm.selectedTab = item.value}}},[_vm._v(_vm._s(item.text))])}),1),(_vm.selectedTab === 'gadgets')?_c('v-card',{staticClass:"selectedTabItem",attrs:{"elevation":"0","tile":""}},[_c('v-card-actions',{staticClass:"nav-tabs"},[_c('YellowButton',{staticClass:"mr-2",attrs:{"text":"Dodaj gadżet","icon":"plus","small":""},nativeOn:{"click":function($event){return _vm.addGadget()}}}),_c('v-text-area-with-validation',{staticClass:"gadgetInput",attrs:{"label":"Informacje","color":"indigo","outlined":"","dense":"","rules":"max:2048"},on:{"input":_vm.saveGadgetsInformation},model:{value:(_vm.gadgetsInformation),callback:function ($$v) {_vm.gadgetsInformation=$$v},expression:"gadgetsInformation"}})],1),_c('v-card-title',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"tshirt"}}),_vm._v(" Gadżety "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.gadgetSearch),callback:function ($$v) {_vm.gadgetSearch=$$v},expression:"gadgetSearch"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.gadgets.length === 0,"loading-text":"Brak gadżetów","headers":_vm.gadgetsHeaders,"items":_vm.gadgets,"search":_vm.gadgetSearch},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
        var item = ref.item;
return [_c('v-img',{attrs:{"width":"120px","contain":"","height":"50px","src":_vm.getPhoto(item.imgPath),"click":""}})]}},{key:"item.visible",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.visible ? "Tak" : "Nie")+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.editGadget(item.id)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.selectGadgetForDeletion(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])])]}}],null,true)}),_c('GadgetModal',{attrs:{"isVisible":_vm.gadgetModalVisible,"isEdit":_vm.isEditGadget},on:{"closeModal":function($event){return _vm.closeGadgetModal()}}})],1):_vm._e(),(_vm.selectedTab === 'products')?_c('v-card',{staticClass:"selectedTabItem",attrs:{"elevation":"0","tile":""}},[_c('v-card-actions',{staticClass:"justify-space-between"},[_c('YellowButton',{attrs:{"text":"Dodaj produkt","icon":"plus","small":""},nativeOn:{"click":function($event){return _vm.addProduct()}}})],1),_c('v-card-title',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"box-open"}}),_vm._v(" Produkty "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.productSearch),callback:function ($$v) {_vm.productSearch=$$v},expression:"productSearch"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.products.length === 0,"loading-text":"Brak produktów","headers":_vm.productsHeaders,"items":_vm.products,"search":_vm.productSearch},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
        var item = ref.item;
return [_c('v-img',{attrs:{"width":"120px","contain":"","height":"50px","src":_vm.getPhoto(item.imgPath),"click":""}})]}},{key:"item.visible",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.visible ? "Tak" : "Nie")+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.editProduct(item.id)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.selectProductForDeletion(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])])]}}],null,true)}),_c('ProductModal',{attrs:{"isVisible":_vm.productModalVisible,"isEdit":_vm.isEditProduct},on:{"closeModal":function($event){return _vm.closeProductModal()}}})],1):_vm._e(),(_vm.selectedTab === 'codes')?_c('v-card',{staticClass:"selectedTabItem",attrs:{"elevation":"0","tile":""}},[_c('v-card-actions',{staticClass:"justify-space-between"},[_c('YellowButton',{attrs:{"text":"Dodaj kod rabatowy","icon":"plus","small":""},nativeOn:{"click":function($event){return _vm.addPromotionalCode()}}}),_c('YellowButton',{attrs:{"text":"Pobierz raport","icon":"download","small":""},nativeOn:{"click":function($event){return _vm.openPromotionalsCodeReportModal()}}})],1),_c('v-card-title',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"users"}}),_vm._v(" Kody rabatowe "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.promotionalCodeSearch),callback:function ($$v) {_vm.promotionalCodeSearch=$$v},expression:"promotionalCodeSearch"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.promotionalCodes.length === 0,"loading-text":"Brak kodów promocyjnych","headers":_vm.promotionalCodesHeaders,"items":_vm.promotionalCodes,"search":_vm.promotionalCodeSearch,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.editPromotionalCode(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj kod")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.selectPromotionalCodeToRemove(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])])]}},{key:"item.isActive",fn:function(ref){
        var item = ref.item;
return [(item.isActive)?_c('span',[_c('font-awesome-icon',{attrs:{"icon":"check","color":"green"}})],1):_c('span',[_c('font-awesome-icon',{attrs:{"icon":"times","color":"red"}})],1)]}}],null,true)}),_c('PromotionalCodeModal',{attrs:{"isVisible":_vm.promotionalCodeModalVisible,"isEdit":_vm.isEditProduct,"item":_vm.selectedCode},on:{"closeModal":function($event){return _vm.closePromotionalCodeModal()}}}),_c('PromotionalCodesReportModal',{attrs:{"isVisible":_vm.promotionalCodesReportModalVisible},on:{"closeModal":function($event){return _vm.closePromotionalCodesReportModal()}}})],1):_vm._e(),(_vm.selectedTab === 'prices')?_c('v-card',{staticClass:"selectedTabItem",attrs:{"elevation":"0","tile":""}},[_c('PriceAdminCard')],1):_vm._e(),(_vm.selectedTab === 'users')?_c('v-card',{staticClass:"selectedTabItem",attrs:{"elevation":"0","tile":""}},[_c('v-card-title',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"users"}}),_vm._v(" Użytkownicy "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.userSearch),callback:function ($$v) {_vm.userSearch=$$v},expression:"userSearch"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.users.length === 0,"loading-text":"Brak użytkowników","headers":_vm.usersHeaders,"items":_vm.users,"search":_vm.userSearch,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.emailConfirm",fn:function(ref){
        var item = ref.item;
return [_c('div',[(item.emailConfirm)?_c('font-awesome-icon',{attrs:{"icon":"check","color":"green"}}):_c('font-awesome-icon',{attrs:{"icon":"times","color":"red"}})],1)]}},{key:"item.newsletter",fn:function(ref){
        var item = ref.item;
return [_c('div',[(item.newsletter)?_c('font-awesome-icon',{attrs:{"icon":"check","color":"green"}}):_c('font-awesome-icon',{attrs:{"icon":"times","color":"red"}})],1)]}},{key:"item.activeAccount",fn:function(ref){
        var item = ref.item;
return [_c('div',[(item.activeAccount)?_c('font-awesome-icon',{attrs:{"icon":"check","color":"green"}}):_c('font-awesome-icon',{attrs:{"icon":"times","color":"red"}})],1)]}},{key:"item.isDeleted",fn:function(ref){
        var item = ref.item;
return [_c('div',[(item.isDeleted)?_c('font-awesome-icon',{attrs:{"icon":"check","color":"green"}}):_c('font-awesome-icon',{attrs:{"icon":"times","color":"red"}})],1)]}},{key:"item.role.name",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatRole(item.role.name)))])]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.subscriptionExpirationDate",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.subscriptionExpirationDate)))])]}},{key:"item.adminNote",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"note-format",domProps:{"innerHTML":_vm._s(item.adminNote)}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.editAdminNote(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj notatkę")])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.editSubscription(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj Subskrypcję")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.removeUser(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"times","color":"red"}})],1)]}}],null,true)},[_c('span',[_vm._v("Usuń użytkownika")])])]}}],null,true)})],1):_vm._e(),(_vm.selectedTab === 'orders')?_c('v-card',{staticClass:"selectedTabItem",attrs:{"elevation":"0","tile":""}},[_c('OrderListAdminCard')],1):_vm._e(),(_vm.selectedTab === 'categories')?_c('v-card',{staticClass:"selectedTabItem",attrs:{"elevation":"0","tile":""}},[_c('v-card-title',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"box-open"}}),_vm._v(" Kategorie produktów "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.categorySearch),callback:function ($$v) {_vm.categorySearch=$$v},expression:"categorySearch"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.categories.length === 0,"loading-text":"Brak produktów","headers":_vm.categoriesHeaders,"items":_vm.categories,"search":_vm.categorySearch},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
        var item = ref.item;
return [_c('v-img',{attrs:{"width":"120px","contain":"","height":"50px","src":_vm.getPhoto(item.imagePath),"click":""}})]}},{key:"item.visible",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.visible ? "Tak" : "Nie")+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.editCategory(item.id)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])])]}}],null,true)}),_c('CategoryModal',{attrs:{"isVisible":_vm.categoryModalVisible,"isEdit":_vm.isEditCategory},on:{"closeModal":function($event){return _vm.closeCategoryModal()}}})],1):_vm._e(),(_vm.selectedTab === 'tabs')?_c('v-card',{staticClass:"selectedTabItem",attrs:{"elevation":"0","tile":""}},[_c('v-card-title',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"box-open"}}),_vm._v(" Zakładki na stronie głównej "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.tabsSearch),callback:function ($$v) {_vm.tabsSearch=$$v},expression:"tabsSearch"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.tabs.length === 0,"loading-text":"Brak zakładek","headers":_vm.tabsHeaders,"items":_vm.tabs,"search":_vm.tabsSearch},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
        var item = ref.item;
return [_c('v-img',{attrs:{"width":"120px","contain":"","height":"50px","src":_vm.getPhoto(item.imagePath),"click":""}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.editTab(item.id)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])])]}}],null,true)}),_c('TabModal',{attrs:{"isVisible":_vm.tabModalVisible,"isEdit":_vm.isEditTab},on:{"closeModal":function($event){return _vm.closeTabModal()}}})],1):_vm._e(),(_vm.selectedTab === 'faq')?_c('v-card',{staticClass:"selectedTabItem",attrs:{"elevation":"0","tile":""}},[_c('v-card-actions',{staticClass:"justify-space-between"},[_c('YellowButton',{attrs:{"text":"Dodaj pytanie","icon":"plus","small":""},nativeOn:{"click":function($event){return _vm.addFaq()}}})],1),_c('v-card-title',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"box-open"}}),_vm._v(" FAQ "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.faqSearch),callback:function ($$v) {_vm.faqSearch=$$v},expression:"faqSearch"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":!_vm.faqs,"loading-text":"Brak pytań","headers":_vm.faqHeaders,"items":_vm.faqs,"search":_vm.faqSearch},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.editFaq(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.selectFaqForDeletion(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])])]}}],null,true)}),_c('FaqModal',{attrs:{"isVisible":_vm.faqModalVisible,"isEdit":_vm.isEditFaq},on:{"closeModal":function($event){return _vm.closeFaqModal()}}})],1):_vm._e(),(_vm.selectedTab === 'instructionalVideos')?_c('v-card',{staticClass:"selectedTabItem",attrs:{"elevation":"0","tile":""}},[_c('v-card-actions',{staticClass:"nav-tabs"},[_c('YellowButton',{staticClass:"mr-2",attrs:{"text":"Dodaj film","icon":"plus","small":""},nativeOn:{"click":function($event){return _vm.addInstructionalVideo()}}}),_c('v-text-area-with-validation',{staticClass:"gadgetInput",attrs:{"label":"Informacje","color":"indigo","outlined":"","dense":"","rules":"max:2048"},on:{"input":_vm.saveInstructionalVideoInformation},model:{value:(_vm.instructionalVideoInformation),callback:function ($$v) {_vm.instructionalVideoInformation=$$v},expression:"instructionalVideoInformation"}})],1),_c('v-card-title',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"box-open"}}),_vm._v(" Filmy instruktażowe "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.instructionalVideoSearch),callback:function ($$v) {_vm.instructionalVideoSearch=$$v},expression:"instructionalVideoSearch"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":!_vm.instructionalVideos,"loading-text":"Brak pytań","headers":_vm.instructionalVideosHeaders,"items":_vm.instructionalVideos,"search":_vm.instructionalVideoSearch},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
        var item = ref.item;
return [_c('p',{domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.editInstructionalVideo(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.selectInstructionalVideoForDeletion(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])])]}}],null,true)}),_c('InstructionalVideoModal',{attrs:{"isVisible":_vm.instructionalVideoModalVisible,"isEdit":_vm.isEditInstructionalVideo},on:{"closeModal":function($event){return _vm.closeInstructionalVideoModal()}}})],1):_vm._e(),_c('ConfirmDeleteModal',{attrs:{"isVisible":_vm.confirmDeleteModalVisible,"product":_vm.selectedProduct,"gadget":_vm.selectedGadget,"tab":_vm.selectedHomeTab,"promotionalCode":_vm.selectedPromotionalCodeToRemove,"user":_vm.selectedUser,"faq":_vm.selectedFaq,"instructionalVideo":_vm.selectedInstructionalVideo},on:{"closeModal":function($event){return _vm.closeConfirmDeleteModal()}}}),_c('SetDateTimeModal',{attrs:{"isVisible":_vm.dateTimeModal,"datetime":_vm.dateTime,"userId":_vm.selectedUser},on:{"closeModal":function($event){(_vm.dateTimeModal = false), (_vm.selectedUser = '')}}}),(_vm.selectedUser)?_c('EditTextFieldModal',{attrs:{"isVisible":_vm.editNoteModal,"initValue":_vm.selectedUser.adminNote,"title":'Edytuj notatkę',"label":'Notatka'},on:{"closeModal":function($event){_vm.editNoteModal = false},"confirm":_vm.editNote}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }