<template>
  <v-row style="max-width: 1400px; margin: auto" justify="center" dense>
    <Localisation :gadgets="gadgets" />
    <p class="gadgetsInformation" v-if="gadgetsInformation">
      {{ gadgetsInformation }}
    </p>

    <v-col
      cols="6"
      sm="6"
      md="4"
      lg="3"
      xl="3"
      :class="{
        'column-margin':
          index + 1 == gadgets.length &&
          ($vuetify.breakpoint.name === 'xs' ||
            $vuetify.breakpoint.name === 'sm') &&
          gadgets.length % 2 != 0,
      }"
      v-for="(gadget, index) in gadgets"
      :key="gadget.id"
    >
      <GadgetCard
        :src="gadget.imgPath"
        :text="gadget.name"
        :link="gadget.link"
        :id="gadget.id"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from "../axios";
import Localisation from "../components/Localisation";
import GadgetCard from "../components/GadgetCard";
export default {
  name: "GadgetsPage",
  components: {
    GadgetCard,
    Localisation,
  },
  data: () => ({
    gadgetsInformation: "",
  }),
  async created() {
    this.$store.dispatch("gadgets/fetchGadgets");
    const response = await axios.get(`custom-text/gadgetShopInfo`);
    this.gadgetsInformation = response.data;
  },
  methods: {},

  computed: {
    gadgets() {
      return this.$store.getters["gadgets/getGadgets"];
    },
  },
};
</script>

<style scoped>
.column-margin {
  margin-right: 50% !important;
}
.gadgetsInformation {
  width: 100%;
  text-align: left;
  padding-left: 4px;
  font-weight: bold;
  margin-top: 1rem;
}
</style>
