<template>
  <v-row style="max-width: 796px; margin: auto" justify="center" dense>
    <v-col cols="12" class="text-center">
      <v-img class="black--text align-end gadget-image my-8" :src="getPhoto()">
      </v-img>
      <h1 class="my-4">{{ gadget.name }}</h1>
      <p class="text-justify my-8">{{ gadget.description }}</p>
      <p class="text-justify my-8">{{ gadget.note }}</p>
    </v-col>
  </v-row>
</template>

<script>
import { URL } from "../config";
import router from "../router";
export default {
  name: "GadgetDetails",
  components: {},
  data: () => ({
    id: "",
  }),
  created() {
    const gadgetId = router.currentRoute.params.id;
    if (gadgetId) {
      this.$store.dispatch("gadgets/fetchGadget", gadgetId);
    } else {
      router.push({ name: "gadgets" });
    }
  },
  methods: {
    getImageUrl() {
      return URL;
    },
    getPhoto() {
      let path = "/uploads/";
      if (this.src && this.src === "no-photo.png") {
        path = "/assets/";
      }
      return this.getImageUrl() + path + this.gadget.imgPath;
    },
  },

  computed: {
    gadget() {
      return this.$store.getters["gadgets/getGadget"];
    },
  },
};
</script>

<style scoped>
.column-margin {
  margin-right: 50% !important;
}
.gadget-image {
  margin: 0 auto;
  width: 100%;
  max-width: 512px;
  max-height: 512px;
  object-fit: cover;
}
</style>
