import Vue from "vue";
import Router from "vue-router";
import jwt_decode from "jwt-decode";
import store from "./store";
import moment from "moment";
import axios from "./axios";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import ResetPasswordPage from "./pages/ResetPasswordPage.vue";
import NewPasswordPage from "./pages/NewPasswordPage.vue";
import EntryPage from "./pages/EntryPage.vue";
import EmailSentPage from "./pages/EmailSentPage.vue";
import EmailConfirmedPage from "./pages/EmailConfirmedPage.vue";
import HomePage from "./pages/HomePage.vue";
import AdminPage from "./pages/AdminPage.vue";
import CustomerPage from "./pages/CustomerPage.vue";
import CategoriesPage from "./pages/CategoriesPage.vue";
import GadgetsPage from "./pages/GadgetsPage.vue";
import ProductsPage from "./pages/ProductsPage.vue";
import ProductPage from "./pages/ProductPage.vue";
import MyOfficePage from "./pages/MyOfficePage.vue";
import SubscriptionPage from "./pages/SubscriptionPage.vue";
import PaymentStatus from "./pages/PaymentStatus.vue";
import ContactPage from "./pages/ContactPage.vue";
import HelpPage from "./pages/HelpPage.vue";
import SettingsPage from "./pages/SettingsPage.vue";
import SimulationPage from "./pages/SimulationPage.vue";
import CalculatorPage from "./pages/CalculatorPage.vue";
import LandingPage from "./pages/LandingPage.vue";
import CalculationOfferPage from "./pages/CalculationOfferPage.vue";
import CalculationPage from "./pages/CalculationPage.vue";
import ErrorPage from "./pages/ErrorPage.vue";
import RegulationsPage from "./pages/RegulationsPage.vue";
import PrivacyPage from "./pages/PrivacyPage.vue";
import AddToDesktopPage from "./pages/AddToDesktopPage.vue";
import GadgetDetails from "./pages/GadgetDetails.vue";
import RecommendationsView from "./pages/RecommendationsView.vue";
import HowUseAppPage from "./pages/HowUseAppPage.vue";
import PdfPreviewPage from "./pages/PdfPreviewPage.vue";
import { SENTRY_DSN, isProduction } from "./config";
Vue.use(Router);

const checkSubscription = async () => {
  const token = localStorage.getItem("token");
  let user = store.getters["user/getUser"];
  let hours = 24;
  let subscriptionExpirationDate;
  let start = moment();
  let end;
  let duration;
  if (!user) {
    await store.dispatch("user/fetchUser");
    user = await store.getters["user/getUser"];
    subscriptionExpirationDate = user.subscriptionExpirationDate;
    end = moment(subscriptionExpirationDate);
    duration = moment.duration(end.diff(start));
    hours = parseInt(duration.asHours());
  } else {
    subscriptionExpirationDate = user.subscriptionExpirationDate;
    end = moment(subscriptionExpirationDate);
    duration = moment.duration(end.diff(start));
    hours = parseInt(duration.asHours());
  }
  const response = await axios.get("user/check-subscription");
  const subscriptionExpired = moment().isAfter(
    moment(response.data.expirationDate)
  );

  if (hours <= 0 && token !== null && user) {
    if (!subscriptionExpired) {
      return true;
    } else {
      return false;
    }
  }

  return !subscriptionExpired ? true : false;
};

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/welcome",
      name: "landing-page",
      component: LandingPage,
    },
    {
      path: "/",
      redirect: { name: "home" },
    },
    {
      path: "/entry",
      name: "entry",
      component: EntryPage,
    },
    {
      path: "/contact",
      name: "contact",
      component: ContactPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/help",
      name: "help",
      component: HelpPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: SettingsPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: ResetPasswordPage,
    },
    {
      path: "/set-new-password",
      name: "set-new-password",
      component: NewPasswordPage,
    },
    {
      path: "/email-sent",
      name: "email-sent",
      component: EmailSentPage,
    },
    {
      path: "/email-confirmed",
      name: "email-confirmed",
      component: EmailConfirmedPage,
    },
    {
      path: "/home",
      name: "home",
      component: HomePage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/simulation",
      name: "simulation",
      component: SimulationPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: PrivacyPage,
    },
    {
      path: "/regulations",
      name: "regulations",
      component: RegulationsPage,
    },
    {
      path: "/calculator",
      name: "calculator",
      component: CalculatorPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/calculation/:calculationId",
      name: "calculation",
      component: CalculationPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/calculation-offer/:calculationId",
      name: "calculation-offer",
      component: CalculationOfferPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/my-office",
      name: "my-office",
      component: MyOfficePage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/my-office/customer/:customerId",
      name: "customer",
      component: CustomerPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/gadgets",
      name: "gadgets",
      component: GadgetsPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/gadgets/:id",
      name: "gadgetDetails",
      component: GadgetDetails,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/categories",
      name: "categories",
      component: CategoriesPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/categories/:categoryId/",
      name: "products",
      component: ProductsPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/categories/:categoryId/product/:productId",
      name: "product",
      component: ProductPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin",
      name: "admin",
      component: AdminPage,
      meta: {
        requiresAuth: true,
        isAdmin: true,
      },
    },
    {
      path: "/subscription",
      name: "subscription",
      component: SubscriptionPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/payment-status",
      name: "payment-status",
      component: PaymentStatus,
    },
    {
      path: "/add-to-desktop",
      name: "add-to-desktop",
      component: AddToDesktopPage,
    },
    {
      path: "/recommendations",
      name: "recommendations",
      component: RecommendationsView,
    },
    {
      path: "/how-use-app",
      name: "how-use-app",
      component: HowUseAppPage,
    },
    {
      path: "/pdf-preview",
      name: "pdf-preview",
      component: PdfPreviewPage,
    },
    {
      path: "*",
      component: ErrorPage,
    },
  ],
  scrollBehavior: function(to) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

if (isProduction) {
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const pathsThatRequireSubcription = [
  "/calculator",
  "/categories",
  "/my-office",
  "/simulation",
];

router.beforeEach(async (to, from, next) => {
  let isSubscriptionActive;
  const token = localStorage.getItem("token");
  if (pathsThatRequireSubcription.includes(to.path) && token !== null) {
    const subscriptionResponse = await checkSubscription();
    isSubscriptionActive = subscriptionResponse;
    if (isSubscriptionActive === false) {
      router.push({ name: "home" });
      store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "red",
          icon: "exclamation-triangle",
          message: "Subskrypcja wygasła!",
        },
        { root: true }
      );
      store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    }
  }
  store.dispatch("publics/checkVersion", true);
  if (to.name === "home" && !from.meta.requiresAuth) {
    store.commit("publics/SET_LOADING", true);
  }
  if (from.name != null) {
    if (from.name === "my-office" && to.name === "my-office") {
      //
    } else if (from.name === "my-office" && to.name != "my-office") {
      localStorage.setItem("lastRoute", from.name);
    } else {
      localStorage.setItem("lastRoute", from.name);
    }
  }

  if (to.meta.requiresAuth) {
    try {
      jwt_decode(window.localStorage.getItem("token"));
    } catch (err) {
      store.dispatch("auth/forceLogout");
    }
    if (to.meta.isAdmin) {
      const { role } = jwt_decode(window.localStorage.getItem("token"));
      if (role === "ADMIN") {
        next();
      } else {
        router.push("/welcome");
      }
    }
  } else {
    next();
  }
  next();
});

router.afterEach(() => {
  setTimeout(function() {
    store.commit("publics/SET_LOADING", false);
  }, 1500);
});

export default router;
