<template>
  <v-app>
    <NavigationDrawer
      class="navigation-drawer"
      v-if="!navigation.includes($route.name) && !getIsLoading"
    />
    <Snackbar />
    <v-main
      style="min-height: 100vh"
      :class="{
        background: background.includes($route.name),
      }"
      @click.native="onClick()"
    >
      <v-container
        fluid
        :fill-height="fillHeight.includes($route.name)"
        :class="{
          'px-0': container.includes($route.name),
          'py-0': getIsLoading,
          'pt-0': noPaddingTop.includes($route.name),
          'container-padding': containerPadding.includes($route.name),
        }"
      >
        <!-- @click="$router.go(-1)" -->
        <v-btn
          v-if="!arrow.includes($route.name)"
          @click="moveToHomePage"
          fab
          width="48px"
          height="48px"
          :class="{ arrowMargin: $vuetify.breakpoint.mdAndUp }"
          class="shadow"
          color="#fbc501"
          style="
            background-image: linear-gradient(
              to bottom,
              #fcde6f 1%,
              #fcc502 35%
            );
            z-index: 10000000;
          "
          fixed
          :top="$vuetify.breakpoint.mdAndUp"
          :left="$vuetify.breakpoint.mdAndUp"
          :bottom="
            $vuetify.breakpoint.name === 'xs' ||
            $vuetify.breakpoint.name === 'sm'
          "
          :right="
            $vuetify.breakpoint.name === 'xs' ||
            $vuetify.breakpoint.name === 'sm'
          "
        >
          <font-awesome-icon
            style="cursor: pointer"
            icon="arrow-left"
            size="2x"
          />
        </v-btn>
        <div
          v-if="getIsLoading"
          class="text-center d-flex justify-center align-center"
          style="background-color: #fbc501; width: 100vw; height: 100vh"
        >
          <v-progress-circular
            :size="100"
            indeterminate
            color="black"
          ></v-progress-circular>
        </div>
        <router-view v-else />
      </v-container>
    </v-main>
    <Footer v-if="!footer.includes($route.name)" style="width: 100%" />
  </v-app>
</template>

<script>
import NavigationDrawer from "./components/NavigationDrawer";
import Footer from "./components/Footer";
import Snackbar from "./components/Snackbar";

export default {
  name: "App",
  components: {
    Footer,
    NavigationDrawer,
    Snackbar,
  },
  data: () => ({
    counter: 0,
    container: ["calculator", "home", "simulation"],
    containerPadding: ["subscription", "landing-page"],
    arrow: [
      "home",
      "privacy",
      "regulations",
      "entry",
      "reset-password",
      "set-new-password",
      "email-sent",
      "email-confirmed",
      "landing-page",
      "payment-status",
    ],
    fillHeight: [
      "email-sent",
      "email-confirmed",
      "reset-password",
      "set-new-password",
    ],
    noPaddingTop: ["calculation-offer"],
    background: [
      "email-sent",
      "email-confirmed",
      "reset-password",
      "set-new-password",
      "entry",
      "regulations",
      "privacy",
    ],
    navigation: [
      "email-sent",
      "email-confirmed",
      "reset-password",
      "set-new-password",
      "entry",
      "landing-page",
    ],
    footer: [
      "email-sent",
      "email-confirmed",
      "reset-password",
      "set-new-password",
      "entry",
      "home",
      "regulations",
    ],
  }),
  computed: {
    isLoggedIn: function () {
      return this.$store.getters["auth/isLoggedIn"];
    },
    getIsLoading() {
      return this.$store.getters["publics/getIsLoading"];
    },
  },
  async created() {
    window.addEventListener("beforeunload", this.leaving);
    if (this.$route.name === "home" || this.$route.name === "my-office")
      await this.$store.dispatch("user/checkSubscription");
  },
  methods: {
    onClick() {
      this.counter++;
      this.$store.commit("publics/SET_BACKGROUND_CLICK", this.counter);
    },
    leaving() {
      localStorage.removeItem("subscriptionCounter");
      localStorage.removeItem("contactsCounter");
    },
    moveToHomePage() {
      const exceptions = ["products", "product"];

      if (exceptions.includes(this.$route.name)) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>

<style lang="scss">
.container-padding {
  padding: 0;
}

// .navigation-drawer {
//   margin-bottom: 12px;
// }
.grey-background {
  background-color: rgba(0, 0, 0, 0.02);
}
.shadow {
  -webkit-box-shadow: 0px 10px 6px -12px #000000;
  box-shadow: 0px 10px 6px -12px #000000;
}
.font {
  font-family: "BebasNeue", sans-serif !important;
}
.padding {
  padding: 10px 0px 0px 40px !important;
}
.grad {
  background-image: linear-gradient(
    to bottom,
    rgb(255, 255, 255, 0.1) 50%,
    rgba(0, 0, 0, 0.3) 150%
  );
}
@font-face {
  font-family: BebasNeue;
  src: url("./fonts/BebasNeue-Regular.ttf") format("truetype");
}
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.background {
  background-color: #fbc501;
}
.wrapper {
  height: calc(100% - 50px);
}
.noFooter {
  height: calc (100%);
}
.greenText {
  color: #00b30f;
}
.redText {
  color: #cc0000;
}
.blueText {
  color: #0050e8;
}
.yellowText {
  color: #f2be02;
}
.tableColor {
  background: rgb(252, 197, 2, 0.15) !important;
}
</style>
<style lang="scss">
.v-text-field__details {
  margin-bottom: 0px !important;
}
.v-data-table__wrapper {
  color: black;
}
th {
  color: black !important;
}
.arrowMargin {
  margin-top: 70px;
}
.break {
  word-break: break-word;
}
</style>
<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
::v-deep .v-btn:before {
  background-color: #fbc501;
}

::v-deep .v-text-field__slot {
  font-size: 12px;
}
::v-deep .v-text-field label {
  font-size: 12px;
}
/* HIDE YOUTUBE CONTROLS IN PLYR VIDEO PLAYER */
.plyr iframe[id^="youtube"] {
  top: -50%;
  height: 200%;
}
iframe {
  pointer-events: none;
}

//snackbar
::v-deep .v-snack {
  width: 340px;
}
::v-deep .v-snack__wrapper {
  min-width: 300px;
  justify-self: start;
  margin-bottom: 1rem;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-width: 300px;
  }
}
</style>
