import { render, staticRenderFns } from "./CustomerCategoriesModal.vue?vue&type=template&id=25a2ca59&scoped=true&"
import script from "./CustomerCategoriesModal.vue?vue&type=script&lang=js&"
export * from "./CustomerCategoriesModal.vue?vue&type=script&lang=js&"
import style0 from "./CustomerCategoriesModal.vue?vue&type=style&index=0&id=25a2ca59&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a2ca59",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardActions,VDialog,VRow,VSelect,VToolbar,VToolbarTitle})
