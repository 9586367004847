import moment from "moment";

export const dateOnly = (date) => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD");
};

export const dateWithTime = (date) => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD hh:mm:ss");
};

export const dateCalcDays = (date) => {
  if (!date) return "-";
  return moment(date)
    .locale("pl")
    .calendar();
};
